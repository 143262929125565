#header {
  // position: absolute;
  width: 100%;
  padding: 35px 0;
  background: $footer-bg;

  .logo {
    padding-left: 15px;
  }

  nav {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    font-weight: 300;
    transition: 0.2s ease-in-out;
    position: relative;
    flex-wrap: nowrap;
    padding: 0 45px;

    .nav_list {
      list-style: none;
      @include align-center-justify-end;
      margin-left: 0;
      padding: 0 4vh;
      margin-bottom: 0;
    }

    .nav_link {
      cursor: pointer;
      color: $menu-txt;
      text-decoration: none;
      margin-right: 50px;
      position: relative;
      padding: 8px 15px;
      font-size: $fs-base;
      margin: 0 20px;
      line-height: $fs-base;
      font-weight: $fw-medium;
      display: flex;
      align-items: center;
      transition: all 0.5s ease-in-out;
      border-radius: 30px;
      &.active {
        background: linear-gradient(
          90deg,
          rgb(196, 183, 220) 0%,
          rgb(224, 217, 235) 100%
        );
        color: #7141c7;
      }
    }
  }
}

nav .nav_list .nav_link:active::before {
  background-color: #00dcff;
}

nav .nav_list .nav_link:hover::before,
nav .nav_list .nav_link:focus::before {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.sm-logo {
  display: none;
}

.nav-toggle {
  display: none;
}

@media only screen and (max-width: 1440px) {
  #header {
    padding: 25px 0;
  }
}
@media only screen and (max-width: 1200px) {
  #header {
    nav {
      .nav_link {
        margin: 12px 10px;
      }
    }
  }
}

@media only screen and (max-width: 1110px) {
  #header {
    .nav {
      padding: 0 45px;
      .nav_list {
        padding: 0;
      }
    }
    .last-child {
      padding: 15px 0;
    }
  }
}
@media only screen and (max-width: 1024px) {
  #header {
    position: sticky;
    z-index: 2;
    width: 100%;
    padding: 0;
    top: 0;
    height: 100px;

    .sm-logo {
      display: flex;
      padding: 20px 0 17px 20px;
      position: absolute;
      top: 0;

      img {
        margin: inherit;
      }
    }

    .nav-toggle {
      display: block;
      position: absolute;
      top: 35px;
      right: 20px;
      background: transparent;
      border: 0;
    }
  }

  .nav {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    transition: -webkit-transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1),
      -webkit-transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    height: 100vh;
    .nav_list {
      list-style: none;
      display: flex;
      height: 100vh;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
      justify-content: center !important;
      text-align: center;
      align-items: ce !important;
      margin: 0;
      background: $white;
      width: 100%;
      .nav_item {
        &:last-child {
          margin-top: 15px;
        }
      }
    }
    .nav-contact {
      margin-top: 30px;
      a {
        font-size: $fs-medium;
      }
    }
  }

  .nav .logo {
    display: none;
  }

  .nav-open .nav {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  .nav-open .nav-toggle {
    z-index: 999;
  }

  .nav-open .nav {
    top: 0;
    padding: 0 !important;
  }

  .hamburger {
    cursor: pointer;
    padding: 10px 35px 16px 0px;
  }

  .hamburger span,
  .hamburger span:before,
  .hamburger span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 35px;
    background: $primary-color;
    position: absolute;
    display: inline-block;
    content: "";
    left: 0;
  }

  .hamburger span:before {
    top: -10px;
  }

  .hamburger span:after {
    bottom: -10px;
  }

  .hamburger span,
  .hamburger span:before,
  .hamburger span:after {
    -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .nav-open .hamburger span {
    background-color: transparent;
  }

  .nav-open .hamburger span:before,
  .nav-open .hamburger span:after {
    top: 0;
  }

  .nav-open .hamburger span:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .nav-open .hamburger span:after {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
    top: 10px;
  }
}

@media only screen and (max-width: 575px) {
  #header {
    height: 80px;
    .sm-logo {
      img {
        width: 130px;
      }
    }
    nav {
      .nav_link {
        font-size: $fs-medium;
        padding: 12px 15px;
      }
    }
  }
}
