footer {
  background: $footer-bg;
  padding-top: 60px;

  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    margin: 0;

    @media only screen and (max-width: 992px) {
      padding: 0;
      margin: 30px 0;
      grid-template-columns: repeat(3, 2fr);
    }

    @media only screen and (max-width: 685px) {
      grid-template-columns: repeat(2, 2fr);
    }

    @media only screen and (max-width: 500px) {
      grid-template-columns: repeat(1, 2fr);
    }

    li {
      font-size: $fs-h6;
      line-height: 26px;
      a {
        color: $footer-text;
      }
    }
  }

  .contectInfo {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    div {
      text-align: right;
    }
    h5 {
      @include text-Gradient(22%, 50%, 100%);
    }
    a {
      text-decoration: none;
      line-height: 29px;
      font-size: 14px;
      color: $footer-a;
    }

    img {
      margin-left: 18px;
      margin-top: 8px;
    }
  }

  .copyRight {
    width: 90%;
    margin: 0 auto;
    padding: 34px 0;
    text-align: center;

    .lineStyle {
      width: 100%;
      height: 2px;
      background: rgb(246, 238, 254);
      background: linear-gradient(
        0deg,
        rgba(143, 80, 168, 1) 50%,
        rgba(246, 238, 254, 1) 50%
      );
    }

    p {
      font-size: 14px;
      line-height: 20px;
      padding-top: 32px;
    }
  }
}
