* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 100%;
}
body {
  font-family: $fontFamily;
}

h1 {
  font-size: $fs-h1;
  font-weight: $fw-bold;
  line-height: 4.463rem;
}

h2 {
  font-size: $fs-h2;
  font-weight: $fw-bold;
  line-height: 60px;
  @include text-Gradient(22%, 50%, 100%);
  display: inline-block;
}

h5 {
  font-size: $fs-h4;
  line-height: 29px;
  font-weight: $fw-bold;
}

h6 {
  color: $primary-color;
  letter-spacing: 6px;
  font-weight: $fw-regular;
  text-transform: uppercase;
}
p {
  font-size: 16px;
  line-height: 24px;
  color: $bodytext;
  margin-bottom: 0;
}

section {
  padding: 140px 0;
}
.wrapper {
  position: relative;
  overflow: hidden;
}
img {
  max-width: 100%;
}
.container {
  max-width: 1110px;
}
#loom-companion-mv3 {
  display: none;
}
@media only screen and (max-width: 1440px) {
  h1 {
    font-size: 3.25rem;
    line-height: 4rem;
  }
}
@media only screen and (max-width: 1110px) {
  .container {
    max-width: 90%;
  }
}
@media only screen and (max-width: 992.98px) {
  section {
    padding: 100px 0;
  }
}
@media only screen and (max-width: 575px) {
  html {
    font-size: 80%;
  }
  section {
    padding: 80px 0;
  }
  h1 {
    font-size: 3rem;
    line-height: 3.563rem;
  }
  h2 {
    line-height: 45px;
  }
}
