.app-button {
  @include buttonBg;
  padding: 12px 55px 12px 35px;
  color: $white;
  @include border-radius(30px);
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 23px;
    right: 18px;
    width: 18px;
    height: 2px;
    background: $white;
    transition: all 0.3s ease-in-out;
  }
  &::before {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(
      to bottom,
      rgba(229, 172, 142, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(229, 172, 142, 0)
    );
    transform: rotateZ(60deg) translate(-5em, 7.5em);
  }
  &:hover {
    &::after {
      right: 14px;
    }
    &::before {
      animation: sheen 1s forwards;
    }
  }
  &.btn-white {
    background: $white;
    color: $primary-color;
    &::after {
      background: $primary-color;
    }
  }
}
a {
  text-decoration: none;
}
button {
  outline: 0;
  border: 0;
}
@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(1em, -9em);
  }
}
