$bodytext: #484848;
$white: #ffffff;
$primary-color: #1e41bb;
$secondary-color: #1e41bb;
$middle-color: #736ffe;
$secondary-txt: #212121;
$light-red: #ececec;
$footer-bg: #ececec;
$menu-txt: #2b2b2b;
$card-bg: #edf0ff;
$lightgray-bg: #f4f4f4;
$titleBadge-bg: #7141c7;
$footer-text: #404040;
$white-grey: #f0f0f0;
$grey: #949494;
$footer-a: #222322;
$header-sticky: #f9f9f9;
$about-team-bg: #eeeeee;
$about-service-bg: #f0f0f0;
$menu-bg: #7339a4;
$menu-activelink: #00dcff;
$field-bg: #f5f5f5;
$error-text: #ff3333;
