@mixin align-center {
  display: flex;
  align-items: center;
}

@mixin justify-center {
  display: flex;
  justify-content: center;
}

@mixin align-justify-center {
  @include align-center;
  justify-content: center;
}

@mixin row-align-justify-center {
  @include align-center;
  flex-direction: row;
}

@mixin align-center-justify-end {
  @include align-center;
  justify-content: flex-end;
}

@mixin align-center-justify-start {
  @include align-center;
  justify-content: flex-start;
}

@mixin align-center-justify-between {
  @include align-center;
  justify-content: space-between;
}

@mixin text-Gradient($colVal_A, $colVal_B, $colVal_C) {
  background: linear-gradient(
    to right,
    $primary-color $colVal_A,
    $middle-color $colVal_B,
    $secondary-color $colVal_C
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradient-background($angle, $color1, $color2, $color3) {
  background-image: -moz-linear-gradient(
    $angle,
    $color1 0%,
    $color2 50%,
    $color3 100%
  );
  background-image: -webkit-linear-gradient(
    $angle,
    $color1 0%,
    $color2 50%,
    $color3 100%
  );
  background-image: -ms-linear-gradient(
    $angle,
    $color1 0%,
    $color2 50%,
    $color3 100%
  );
  background-image: linear-gradient(
    $angle,
    $color1 0%,
    $color2 50%,
    $color3 100%
  );
}
@mixin gradientBg() {
  background: linear-gradient(
    138deg,
    rgb(30, 65, 187) 0%,
    rgb(73, 88, 221) 27%,
    rgb(116, 111, 254) 51%,
    rgb(111, 75, 211) 73%,
    rgb(106, 39, 168) 100%
  );
}

@mixin buttonBg() {
  background: linear-gradient(
    270deg,
    rgb(127, 63, 180) 0%,
    rgb(70, 36, 102) 100%
  );
}
@mixin num-text() {
  background: #1e41bb;
  background: linear-gradient(to right, #1e41bb 38%, #706ffe 63%, #6a29aa 76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin work-bg() {
  background: rgb(233, 215, 255);
  background: linear-gradient(
    90deg,
    rgba(233, 215, 255, 1) 0%,
    rgba(237, 240, 255, 1) 54%
  );
}
@mixin capabilities-bg() {
  background-image: linear-gradient(
    to right bottom,
    #6a27a8,
    #6d36b8,
    #7044c8,
    #7251d8,
    #735ee8,
    #6d60ea,
    #6761eb,
    #6063ed,
    #525ae0,
    #4352d4,
    #3249c7,
    #1e41bb
  );
}
@mixin border-radius($pixel) {
  border-radius: $pixel;
}

@mixin box-size($width, $height) {
  width: $width;
  height: $height;
}
