// Banner section
.alpha-home {
  position: relative;
  height: calc(100dvh - 125px);
  background: $footer-bg;

  .banner-grp {
    margin: 0 auto;
    @include align-center;
    height: 100%;

    .banner-cont {
      p {
        max-width: 70%;
      }

      h5 {
        display: inline-block;
        border-radius: 22px;
        background: $titleBadge-bg;
        color: $white;
        padding: 17px 25px;
        font-size: 50px;
        height: 72px;
      }
    }

    .row {
      align-items: center;
    }
  }

  .container {
    max-width: 75%;
  }
}

// About us
.about-sec {
  padding-bottom: 0;

  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

// Work
.work-sec {
  padding-bottom: 0;
  .work-row {
    background: $lightgray-bg;
    padding: 50px 0px 60px 50px;
    border-radius: 25px;
    position: relative;

    @media only screen and (max-width: 575px) {
      padding: 30px;
    }

    .work-content {
      display: flex;
      margin-top: -90px;
      @media only screen and (max-width: 991px) {
        padding-top: 25px;
        margin-top: 0;
      }

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        padding-top: 0;
      }

      .work-points {
        width: 100%;
        padding-right: 50px;
        .work-title {
          font-size: $fs-h5;
        }

        @media only screen and (max-width: 767px) {
          padding-top: 10px;
          position: relative;
          padding-left: 85px;
          padding-right: 0;
        }

        @media only screen and (max-width: 575px) {
          padding-top: 4px;
          padding-bottom: 10px;
          padding-left: 0px;
          width: 100%;
        }

        h3 {
          @include num-text;
          position: unset;
          font-size: $h3-num;
          font-weight: $fw-bold;

          @media only screen and (max-width: 991px) {
            position: unset;
          }

          @media only screen and (max-width: 575px) {
            font-size: 2.9rem;
          }
        }
      }
    }
  }
}

.clientList {
  .slider {
    margin-top: 60px;

    figure {
      margin-bottom: 0;
    }

    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
      }

      img {
        cursor: pointer;
        filter: grayscale(100%);
        margin: 0 auto;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;

        &:hover {
          filter: none;
          opacity: 1;
        }
      }
    }
  }
}

.connectWithUs {
  .rightSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media only screen and (max-width: 992px) {
      margin-top: 40px;
    }

    h2 {
      margin-bottom: 40px;
    }

    .contectDetails {
      background: $light-red;
      padding: 20px;
      margin-bottom: 12px;
      text-align: left;
      max-width: 400px;
      width: 100%;
      border-radius: 24px;
      margin-left: 42px;
      position: relative;

      a {
        text-decoration: none;
        color: $bodytext;
        font-weight: $fw-semibold;
        font-size: 16px;
      }

      .location {
        max-width: 255px;
      }

      .icon {
        position: absolute;
        top: 20px;
        left: -42px;
      }
    }
  }
}

// Our Services
.slider-grp {
  .slick-track {
    display: flex;
    justify-content: space-between;
    gap: 25px;
  }

  .slick-slide {
    padding: 25px;
    @include border-radius(22px);
    height: 130px;
    background: $footer-bg;
    cursor: pointer;

    h3 {
      @include text-Gradient(22%, 50%, 100%);
      padding-top: 15px;
      font-size: 24px;
    }

    .active-icon {
      display: none;
    }

    &.slick-current {
      @include gradientBg;

      h3 {
        -webkit-text-fill-color: inherit;
        color: $white;
      }

      .active-icon {
        display: block;
      }

      .title-icon {
        display: none;
      }
    }
  }
}

.talent-content {
  margin-top: 50px;
  padding: 50px 0 50px 50px;
  position: relative;

  h4 {
    @include text-Gradient(22%, 50%, 100%);
    display: inline-block;
    font-weight: $fw-bold;
  }

  &::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    @include border-radius(22px);
    background: $footer-bg;
  }

  .talent-items {
    padding-top: 40px;
    li {
      position: relative;
      list-style: none;
      padding-bottom: 15px;
      font-size: $fs-medium;

      &::before {
        content: "";
        position: absolute;
        left: -20px;
        top: 10px;
        width: 8px;
        height: 8px;
        @include border-radius(30px);
        @include gradientBg;
      }
    }
  }
}

// Expertise Services

.expertise-group {
  padding-top: 20px;
  margin-top: 25px;
  .expertise-list {
    ul {
      display: grid;
      grid-template-columns: 48% 48%;
      gap: 30px;
      padding: 0;
      width: 100%;
      li {
        background: $light-red;
        padding: 25px;
        @include border-radius(22px);
        list-style: none;
        cursor: pointer;
        &.active-card {
          @include gradientBg();
          p {
            color: $white;
          }
        }
        &.expertise-item {
          p {
            max-width: 95%;
            padding-top: 30px;
            font-size: 18px;
          }
        }
      }
    }
  }
  .experties-bg {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 98%;
      height: 63%;
      bottom: 15px;
      left: 15px;
      z-index: -1;
      @include border-radius(22px);
      background: $light-red;
    }
    ul {
      margin: 0;
      padding-top: 50px;
      list-style: none;
      display: grid;
      grid-template-columns: 50% 50%;
      position: relative;
      padding-left: 0;
      li {
        font-size: 16px;
        position: relative;
        padding: 0 15px 15px 15px;
        &::before {
          content: "";
          position: absolute;
          left: -2px;
          top: 8px;
          width: 7px;
          height: 7px;
          @include border-radius(30px);
          @include gradientBg;
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 35px;
        height: 3px;
        left: 0;
        top: 23px;
        @include gradientBg();
      }
    }
    .experties-content {
      padding: 40px;
    }
  }
  .it-expertise {
    .experties-bg {
      &::after {
        left: 0;
      }
    }
    li.expertise-item {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: 20px;
        top: 20px;
        width: 8px;
        height: 8px;
        background: red;
        @include border-radius(30px);
        @include gradientBg();
      }
      p {
        padding-top: 18px;
      }
      &.active-card {
        &::after {
          background: $white;
        }
      }
    }
  }
}

//We Are best
.weare-best {
  position: relative;
  padding: 50px 0 50px 50px;
  h2 {
    -webkit-text-fill-color: inherit;
    color: $white;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include border-radius(22px);
    z-index: -1;
    background: linear-gradient(
      138deg,
      rgb(30, 65, 187) 0%,
      rgb(73, 88, 221) 27%,
      rgb(116, 111, 254) 51%,
      rgb(111, 75, 211) 73%,
      rgb(106, 39, 168) 100%
    );
  }
  ul.best-list {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 30px;
    color: $white;
    padding: 0 20px 0 50px;
    li {
      font-size: 16px;
      padding-right: 40px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .alpha-home {
    height: calc(100dvh - 105px);
  }
}

@media only screen and (max-width: 1366px) {
  .alpha-home {
    .container {
      max-width: 85%;
    }

    .banner-grp {
      .banner-cont {
        p {
          max-width: 85%;
        }

        h5 {
          font-size: 24px;
          height: 50px;
          padding: 10px 25px;
        }
      }

      .banner-kv {
        width: 85%;
        margin: 0 auto;
      }
    }
  }
}
@media only screen and (max-width: 1110px) {
  .alpha-home {
    .container {
      padding-bottom: 40px;
    }
    .banner-grp {
      .banner-cont {
        h5 {
          font-size: $fs-h4;
          height: 40px;
          padding: 5px 25px;
          border-radius: 15px;
        }
        h1 {
          font-size: 2.01rem;
          line-height: 2.8rem;
        }
        p {
          max-width: 83%;
        }
      }
    }
  }
}
@media only screen and (max-width: 992.98px) {
  .alpha-home {
    .banner-grp {
      .row {
        align-items: self-start;
        flex-direction: column-reverse;
      }

      .banner-kv {
        width: 65%;
        margin: 0 auto;
      }
      .banner-cont {
        h1 {
          font-size: 3.1rem;
          line-height: 3.2rem;
        }
        p {
          max-width: 83%;
        }
      }
    }
  }

  .talent-content {
    padding: 40px;

    &::before {
      width: 100%;
    }
  }

  .slider-grp {
    .slick-slide {
      h3 {
        font-size: 24px;
      }
    }
  }
  .about-sec {
    .row {
      flex-direction: column-reverse;
      gap: 10px;
    }
    .about-content {
      margin-top: 0;
      padding-bottom: 30px;
    }
  }
  .weare-best {
    padding: 40px;
    &::before {
      width: 100%;
    }
    ul.best-list {
      padding: 0;
      margin: 30px 0;
      li {
        padding-right: 10px;
      }
    }
  }

  .expertise-list {
    margin-bottom: 40px;
  }
  .connectWithUs {
    .row {
      flex-direction: column-reverse;
      gap: 10px;
    }
    .contactImg {
      padding-top: 25px;
    }
  }
  footer {
    .contectInfo {
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row-reverse;
      gap: 15px;
      h5 {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .alpha-home {
    .container {
      padding-bottom: 0;
    }
    .banner-grp {
      .banner-cont {
        padding-top: 25px;
        margin-bottom: 60px;

        h1 {
          font-size: 3rem;
          line-height: 3.25rem;

          br {
            display: none;
          }
        }

        p {
          max-width: 100%;
        }

        h5 {
          padding: 10px 25px;
          font-size: 23px;
          height: auto;
          border-radius: 12px;
        }
      }

      .banner-kv {
        width: 100%;
      }
    }
  }

  .slider-grp {
    .slick-track {
      display: block;
    }

    .slick-slide {
      width: 100% !important;
      margin-top: 20px;
    }
  }

  .talent-content {
    .talent-items {
      padding-top: 20px;
      grid-template-columns: 100%;

      li {
        padding-bottom: 20px;
      }
    }
  }
  .clientList .slider {
    margin-top: 30px;
  }
  .talent-content {
    padding: 31px;
  }
  .weare-best {
    padding: 38px 30px;
    ul.best-list {
      grid-template-columns: 100%;
      gap: 10px;
    }
  }
  .expertise-group {
    .expertise-list ul {
      grid-template-columns: 100%;
      gap: 20px;
    }
    .experties-bg {
      &::after {
        left: 0;
      }
      ul {
        grid-template-columns: 100%;
      }
      .experties-content {
        padding: 30px;
      }
    }
  }
  .connectWithUs {
    .rightSection {
      .contectDetails {
        margin-left: 0;
        .icon {
          left: 12px;
          top: 23px;
          display: none;
        }
      }
    }
  }
  .expertise-group {
    margin-top: 0;
  }
  .alpha-home {
    height: auto;
    padding-bottom: 40px;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 390px) {
  .connectWithUs {
    .rightSection {
      .contectDetails a {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 926px) and (orientation: landscape) {
  .alpha-home {
    height: 900px !important;
    padding-bottom: 35px;
  }
}
