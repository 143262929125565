.header {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.header-section {
  transition: all 0.3s ease-in-out;

  &.is-sticky {
    background: $header-sticky;
    height: 85px;
    position: sticky;
    top: 0;
    z-index: 9;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 5px 31px -3px rgba(0, 0, 0, 0.1),
      0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
}
.last-child {
  overflow: hidden;
  padding: 15px;
}
