//****  Engineering services section****//

.engineering-section {
  h6 {
    font-size: $fs-h4;
    font-weight: $fw-bold;
    color: $bodytext;
    letter-spacing: normal;
    text-transform: none;
    text-align: start;
  }

  .service-card-grid {
    @include align-justify-center;
    flex-wrap: wrap;
    gap: 50px;
    row-gap: 100px;
    list-style: none;
    margin: auto;
    padding: 0;

    .services-list {
      li {
        width: 259px;
        height: 227px;
      }

      .service-card {
        display: inline-block;
        position: relative;
        cursor: pointer;

        .service-square-img {
          position: relative;
          overflow: hidden;
          border-radius: 25px;

          img {
            transform: scale(1);
            transition: all 0.5s ease-in-out;
          }
        }

        .service-content {
          position: absolute;
          @include border-radius(26px);
          background-color: $footer-bg;
          width: 225px;
          bottom: -50px;
          left: 25px;
          padding: 20px;
          max-height: 110px;
          overflow: hidden;
          transition: all 0.5s ease-in-out, opacity 0.5s ease-in-out;

          .service-title {
            margin-bottom: 0px;
          }

          .service-description {
            text-align: start;
            opacity: 0;
            max-height: 0;
            transition: all 0.5s ease-in-out, opacity 0.5s ease-in-out;
            margin: 0;
            padding-top: 15px;
          }
        }

        &:hover {
          .service-content {
            max-height: 400px;
            .service-description {
              opacity: 1;
              max-height: 100%;
            }
          }

          .service-square-img {
            img {
              transform: scale(1.1);
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
}
.talent-section {
  .title-content {
    text-align: center;

    p {
      font-size: 16px;
      font-weight: $fw-regular;
      color: $bodytext;
      margin: 0 auto;
      width: 80%;
    }
  }

  .service-list-container {
    h3 {
      font-size: $fs-large-base;
      color: $bodytext;
      font-weight: $fw-bold;
      margin-bottom: 20px;
    }

    padding: 34px;
    //   @include justify-center;
    //   flex-direction: column;
    //   align-items: flex-start;

    .service-list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .service-item {
      display: flex;
      align-items: flex-start;
      position: relative;
      padding-left: 20px;
      &::before {
        content: "";
        position: absolute;
        left: -10px;
        top: 8px;
        width: 8px;
        height: 8px;
        @include border-radius(30px);
        @include gradientBg;
      }
      .service-icon {
        margin-right: 20px;
      }

      .service-text {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
  }
}
.service-section {
  .slider-card {
    margin: 0 auto;
    transition: background-color 0.6s ease, color 0.6s ease;
    cursor: pointer;

    h2 {
      font-weight: $fw-semibold;
      @include align-center-justify-start;
    }

    p {
      font-size: $fs-medium;
      font-weight: $fw-regular;
      text-align: start;
    }

    @include border-radius(26px);
    width: 250px;
    height: 200px;
    padding: 30px;
    text-align: center;
    background-color: $footer-bg;

    &.active {
      @include gradientBg;

      p,
      h2 {
        color: $white !important;
        -webkit-text-fill-color: $white !important;
      }
    }
  }
}
.role-section {
  h3 {
    margin-bottom: 20px;
    text-align: center;
    font-size: $fs-large-base;
    color: $bodytext;
    font-weight: $fw-bold;
  }

  .roles-content {
    padding: 50px 0 0px 0;
    position: relative;

    .roles-part {
      &::before {
        content: "";
        position: absolute;
        width: 70%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: -1;
        @include border-radius(22px);
        background: $footer-bg;
      }

      padding: 30px;
      @include justify-center;
      flex-direction: column;
      align-items: flex-start;

      ul {
        padding-left: 1rem;

        li {
          position: relative;
          list-style: none;
          font-size: 16px;
          margin-bottom: 15px;

          &::before {
            content: "";
            position: absolute;
            left: -20px;
            top: 8px;
            width: 7px;
            height: 7px;
            @include border-radius(30px);
            @include gradientBg;
          }
        }
      }
    }
  }
}

.onboarding-section {
  position: relative;
  padding: 0px 0 30px 0px;

  h3 {
    padding-right: 60px;
    text-align: left;
    font-size: $fs-large-base;
    color: $bodytext;
    font-weight: $fw-bold;
  }

  .onboarding-content {
    .onboarding-title {
      position: relative;
      top: 30%;
    }

    .onboarding-image {
      position: relative;
      @include align-center-justify-end;
      top: 30%;
      z-index: 1;

      img {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }

      img.fade-in {
        opacity: 1;
      }
    }
  }

  .slick-slider {
    position: unset;

    .slick-arrow {
      &.slick-prev {
        top: 18%;
        left: 5%;
        // background-color: $white;
      }

      &.slick-next {
        &.slick-disabled {
          // background-color: $white;
        }

        top: 18%;
        left: 13%;
      }
    }
  }

  .onboarding-slider-content {
    position: relative;
    padding: 175px 40px 40px 40px;
    @include border-radius(22px);
    background: $footer-bg;

    .slider {
      .slider-card {
        cursor: pointer;
        margin: 0 auto;
        transition: background-color 0.6s ease, color 0.6s ease;

        h2,
        h1 {
          font-weight: $fw-semibold;
          @include align-center-justify-start;
          color: $bodytext;
          -webkit-text-fill-color: $bodytext !important;
        }
        h1 {
          font-size: $fs-h4;
          text-align: start;
          line-height: 25px;
        }

        p {
          font-size: $fs-medium;
          font-weight: $fw-regular;
          text-align: start;
        }

        @include border-radius(26px);
        width: 211px;
        height: 211px;
        padding: 26px;
        text-align: center;
        background-color: $white;
        transition: all 0.5s ease-in-out;
        &.active {
          @include gradientBg;
          transition: all 0.5s ease-in-out;

          p,
          h2,
          h1 {
            color: $white !important;
            -webkit-text-fill-color: $white !important;
          }
        }
      }
    }
  }
}
.onboarding-image-container {
  padding-right: 50px;
}
.disabled-arrow {
  cursor: no-drop;
  // opacity: 0.5;
  background-color: white;
  pointer-events: none;
}
body .slick-arrow.slick-disable.slick-prev {
  background-image: none;
}
body .slick-slider .slick-arrow.slick-disable.slick-next img {
  filter: brightness(1) invert(0) !important;
}
body .slick-arrow.slick-disable.slick-next {
  background-image: none;
}
body .slick-slider .slick-arrow.slick-disable img {
  filter: brightness(1) invert(0) !important;
}
@media only screen and (max-width: 1560px) {
  .service-section {
    .slick-slider {
      .slick-arrow {
        &.slick-next {
          right: -5% !important;
        }

        &.slick-prev {
          left: -5% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 992.98px) {
  .role-section {
    padding-top: 0;
    padding-left: 0;

    .roles-content {
      .roles-part {
        padding: 70px;

        &::before {
          width: 100%;
        }
      }
    }
  }
  .onboarding-section {
    .slick-slider {
      .slick-arrow {
        &.slick-prev {
          top: 30%;
          left: 4%;
        }

        &.slick-next {
          top: 30%;
          left: 11%;
        }
      }
    }

    .onboarding-content {
      .onboarding-image {
        justify-content: center;
        margin-bottom: 20px;
      }
    }

    .onboarding-slider-content {
      padding-right: 0;
      padding-left: 0;
    }

    h3 {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 840px) {
  .role-section {
    .role-list-image {
      text-align: center;
    }
  }
  .onboarding-section {
    .onboarding-content {
      .onboarding-title {
        position: relative;
        top: 80px;
        padding: 0;
      }
    }

    .slick-slider {
      .slick-arrow {
        &.slick-next {
          left: 16%;
        }
      }
    }

    h3 {
      padding-bottom: 60px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .talent-section {
    .title-content {
      p {
        width: 100%;
      }
    }

    .service-list-container {
      padding: 20px;

      .service-item {
        .service-text {
          margin-top: 0;
        }
      }
    }
  }
  .slider-card {
    margin: 0 auto;
  }
  .role-section {
    padding: 0;

    .roles-content {
      .roles-part {
        padding: 40px;

        ul {
          li {
            padding: 0;
          }
        }

        &::before {
          width: 100%;
        }
      }
    }
  }
  .onboarding-section {
    padding: 0;

    h3 {
      padding: 20px 0;
      margin-top: 20px;
    }

    .onboarding-slider-content {
      padding-right: 0 !important;
      padding-top: 110px;
    }

    .onboarding-content {
      flex-direction: column;

      .onboarding-image-container {
        position: unset;
        padding: 0 20px;
      }

      .onboarding-title {
        position: relative;
        top: 80px;
        padding: 0;
      }

      .onboarding-image {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .engineering-section {
    .service-card-grid {
      .services-list {
        .service-card {
          .service-content {
            right: -10px;
            width: 95%;
            padding: 25px;
            bottom: -35px;
          }
        }
      }
    }
  }
  .talent-section {
    .talent-image {
      text-align: center;
    }

    .service-list-container {
      .service-item {
        .service-text {
          margin-top: 0;
        }
      }

      .service-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  .onboarding-content {
    flex-direction: column;

    .onboarding-title,
    .onboarding-image {
      // text-align: center;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .engineering-section {
    .service-card-grid {
      grid-template-columns: 1fr;
      display: block;
    }
    .service-card {
      width: 100%;
      margin-bottom: 60px;
    }
    .service-card img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 428px) {
  .engineering-section {
    .service-card-grid {
      .services-list {
        .service-card {
          .service-content {
            padding: 20px;
          }
          p {
            padding-top: 5px;
          }
        }
      }
    }
  }
}
