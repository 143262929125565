.notFound {
  width: 25%;
  margin: 0 auto;
  .notfoundBack {
    padding: 30px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .notFound {
    width: 45%;
  }
}
@media only screen and (max-width: 575px) {
  .notFound {
    width: 55%;
  }
}
@media only screen and (max-width: 400px) {
  .notFound {
    width: 65%;
  }
}
@media only screen and (max-width: 320px) {
  .notfound-sec {
    .app-button:after {
      top: 30px;
    }
  }
}
