.field-item {
  margin-bottom: 12px;
  .error-message {
    padding-top: 5px;
    font-size: $fs-light;
    font-weight: $fw-regular;
    color: $error-text;
    line-height: 18px;
    position: relative;
  }
}
.field-control {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 0px;
  @include border-radius(13px);
  border: 0;
  background: $field-bg;
  outline: none;
  &.has-error {
    border: 1px solid $error-text;
  }
}

textarea {
  resize: none;
  height: 200px;
}

.g-map {
  width: 80%;
  height: 460px;
  position: relative;
  @include border-radius(24px);

  &::before {
    content: "";
    position: absolute;
    right: -50px;
    top: -50px;
    width: 65%;
    height: 80%;
    background: $light-red;
    @include border-radius(24px);
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 30px;
    right: -30px;
    width: 5px;
    height: 80px;
    background: url("../../images/line-dot.png") no-repeat;
  }

  iframe {
    @include border-radius(24px);
    border: 0;
  }
}
.get-touch-row {
  margin-top: 25px;
  padding-top: 25px;
}
.contact-img {
  text-align: right;
}

.spinner {
  width: 13px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  display: block;
  margin: 5px 20px;
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 #d8d7d7, -20px 0 #85858575;
    background: #ffffff;
  }
  33% {
    box-shadow: 20px 0 #d8d7d7, -20px 0 #85858575;
    background: #ffffff;
  }
  66% {
    box-shadow: 20px 0 #85858575, -20px 0 #d8d7d7;
    background: #ffffff;
  }
  100% {
    box-shadow: 20px 0 #85858575, -20px 0 #d8d7d7;
    background: #ffffff;
  }
}

@media only screen and (max-width: 992px) {
  .g-map {
    width: 85%;
    margin-top: 60px;
    height: 400px;
  }
  .contact-img {
    text-align: center;
  }
  form {
    padding: 30px 0;
  }
  .get-touch-row {
    margin-top: 0;
    padding-top: 0;
  }
  .g-map::before {
    width: 85%;
    top: -35px;
    right: -40px;
  }
}
