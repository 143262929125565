$fontFamily: "Poppins", sans-serif;

//header
$fs-h1: 3.75rem; // 60px
$fs-h2: 2.813rem; // 45px
$fs-large-base: 2rem; // 32px
$fs-h3: 1.5rem; // 24px
$fs-h4: 1.313rem; // 22px
$fs-h5: 1.25rem; // 20px
$fs-h6: 1rem; // 16px
$fs-medium: 1.25rem; //18px
$fs-base: 1rem;
$h3-num: 4.563rem;
$fs-light: 0.875rem;
//font weight
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-bolder: 800;
$fw-extrabold: 900;
