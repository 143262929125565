body {
  .slick-slider {
    position: relative;

    .slick-arrow {
      width: 62px;
      height: 62px;
      @include gradient-background(
        310deg,
        rgb(106, 39, 168),
        rgb(116, 111, 254),
        rgb(30, 65, 187)
      );
      border-radius: 50px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        filter: brightness(0) invert(1) !important;
      }

      &.slick-disabled {
        background-color: rgb(237, 240, 255);
        background-image: none;

        img {
          filter: none !important;
        }
      }

      &.slick-prev {
        position: absolute;
        left: -8%;

        @media only screen and (max-width: 1560px) {
          left: -4%;
        }

        @media only screen and (max-width: 992px) {
          left: -6%;
        }
      }

      &.slick-next {
        position: absolute;
        right: -8%;

        @media only screen and (max-width: 1560px) {
          right: -4%;
        }

        @media only screen and (max-width: 992px) {
          right: -6%;
        }

        img {
          transform: rotate(177deg);
        }
      }

      &::before {
        display: none;
      }
    }

    .slick-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;

      li {
        margin: 0;
        width: 14px;
        height: 14px;
        button {
          width: 14px;
          height: 14px;
          padding: 0;
        }

        &.slick-active {
          button {
            &::before {
              color: rgb(106, 39, 168);
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
