// engineeringcapabilities
.eng-capability {
  padding-bottom: 60px;
  .border-img {
    padding-top: 40px;
  }
  .container {
    h2 {
      padding-bottom: 25px;
      margin: 0;
    }

    .engineering-card {
      width: 265px;
      @include capabilities-bg();
      border-radius: 25px;
      padding: 40px 30px 40px 25px;
      margin: 0 auto;

      .engineering-icon {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          right: 0px;
          top: -5px;
          width: 8px;
          height: 8px;
          background: $white;
          border-radius: 50%;
        }
      }

      .engineering-content {
        padding-top: 30px;

        h3 {
          color: $white;
          font-size: $fs-medium;
          font-weight: $fw-bold;
        }

        p {
          color: $white;
          font-size: $fs-medium;
        }
      }
    }
  }
}

.industries-sec {
  h2 {
    @include justify-center;
    padding-bottom: 60px;
    margin: 0;
  }

  ul {
    @include align-justify-center;
    gap: 50px;
    flex-wrap: wrap;
    margin: auto;
    padding: 0;

    li {
      list-style: none;
      height: 345px;
      width: 325px;

      .industries-card {
        display: inline-block;
        position: relative;
        cursor: pointer;

        .industries-img {
          position: relative;
          overflow: hidden;
          border-radius: 25px;

          img {
            transform: scale(1);
            transition: all 0.5s ease-in-out;
          }
        }

        .industries-content {
          width: 320px;
          position: absolute;
          background-color: $white-grey;
          padding: 40px;
          border-radius: 20px;
          bottom: -75px;
          right: -25px;
          max-height: 110px;
          overflow: hidden;
          transition: all 0.5s ease-in-out, opacity 0.5s ease-in-out;

          h4 {
            margin-bottom: 0;
          }

          p {
            opacity: 0;
            max-height: 0;
            transition: all 0.5s ease-in-out, opacity 0.5s ease-in-out;
            margin: 0;
            padding-top: 15px;
          }
        }

        &:hover {
          .industries-content {
            max-height: 500px;
          }
          .industries-content p {
            opacity: 1;
            max-height: 100%;
          }

          .industries-img {
            img {
              transform: scale(1.1);
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
}

.product-sec {
  padding-bottom: 0;

  .products-cont ul {
    padding-top: 50px;

    li {
      padding-bottom: 25px;
    }
  }

  .products-list {
    ul {
      display: flex;
      flex-direction: column;
    }
  }

  .products-content {
    ul {
      display: flex;
      flex-direction: column;
    }
  }

  .expertise-list {
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;

      li.expertise-item p {
        padding-top: 0;
      }
    }
  }

  .experties-bg::after {
    display: none;
  }

  .expertise-cont {
    h3 {
      font-size: 30px;
      padding-top: 30px;
      padding-bottom: 20px;
      font-weight: $fw-bold;
    }

    ul {
      padding-top: 50px;

      li.expertise-item {
        padding-bottom: 25px;
        font-weight: $fw-bold;
        list-style: none;

        &::after {
          left: -20px;
          top: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .product-sec {
    li {
      &.expertise-item {
        p {
          font-size: $fs-h6;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .eng-capability {
    .container {
      .row {
        flex-wrap: nowrap;
        .content {
          width: 60%;
        }
        .engineering-card {
          height: 300px;
          width: 40%;
          margin: initial;
          margin-top: 40px;
        }
      }
    }
  }

  .industries-sec {
    h2 {
      padding-bottom: 30px;
    }

    ul {
      li {
        .industries-card {
          .industries-content {
            padding: 30px;
            bottom: -50px;
          }
        }
      }
    }
  }

  .product-sec {
    .expertise-group {
      .expertise-list {
        ul {
          padding-top: 0px;
          display: grid;
        }
      }
      .expertise-cont {
        h3 {
          padding-bottom: 10px;
        }

        li.expertise-item {
          padding-bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .eng-capability {
    .container {
      .row {
        flex-wrap: wrap;
        .content {
          width: 100%;
        }
        .engineering-card {
          height: auto;
          width: inherit;
          margin: 0 auto;
          margin-top: 40px;
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .industries-sec {
    padding-top: 60px;

    ul {
      gap: 20px;

      li {
        .industries-card {
          .industries-content {
            right: -10px;
            width: 95%;
            padding: 25px;
            bottom: -35px;
          }

          &:hover {
            .industries-content p {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
  .eng-capability .border-img {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 370px) {
  .industries-sec {
    h2 {
      padding-bottom: 20px;
    }

    ul {
      gap: 60px;

      li {
        height: auto;

        .industries-card {
          .industries-content {
            padding: 20px;

            p {
              font-size: $fs-h6;
            }
          }

          &:hover {
            .industries-content p {
              padding-top: 5px;
            }
          }
        }
      }
    }
  }
}
