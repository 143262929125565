.alpha-home {
  &.inner-banner {
    height: calc(620px - 125px);
    .banner-grp {
      align-items: flex-start;
      padding-top: 20px;
      h1 {
        padding-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .inner-banner {
    height: auto !important;
    .banner-grp {
      .banner-content {
        padding-bottom: 60px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .alpha-home {
    &.inner-banner {
      .banner-grp {
        padding-top: 0;
        .banner-content {
          padding-bottom: 50px;
          .banner-count {
            padding-top: 20px;
          }
        }
        h1 {
          br {
            display: none;
          }
        }

        .banner-kv {
          width: 85%;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .inner-banner {
    .banner-grp {
      .banner-kv {
        width: 100%;
      }
    }
  }
  .alpha-home.inner-banner .banner-grp {
    align-items: center;
    padding-top: 25px;
  }
}
@media only screen and (max-width: 360px) {
  .inner-banner {
    .banner-grp {
      .banner-count {
        h1 {
          font-size: 35px;
          line-height: 40px;
        }
      }
    }
  }
}
@media only screen and (max-width: 320px) {
  .inner-banner {
    .banner-grp {
      .banner-count {
        h1 {
          font-size: 28px;
        }
        h2 {
          font-size: 25px;
        }
      }
    }
  }
}
