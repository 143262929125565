.innovative-sec {
  padding-bottom: 0;

  .our-goals {
    padding-top: 40px;
    padding-left: 0;

    .separate-goals {
      padding-bottom: 30px;
      gap: 20px;
      display: grid;
      grid-template-columns: 10% 88%;
    }

    .our {
      font-size: $fs-h3;
      font-weight: $fw-bold;
      color: #484848;
      padding-bottom: 10px;
    }
  }
}

.about-sec {
  .about-content {
    p:first-of-type {
      padding-bottom: 30px;
    }
  }
}
.ourserviceList {
  // padding-bottom: 0;

  .slider {
    figure {
      margin-bottom: 0;
    }

    .slick-list {
      .slick-track {
        @include align-center;
      }

      img {
        cursor: pointer;
        margin: 0 auto;
        transition: all 0.3s ease-in-out;
      }
    }
    .serviceSlider {
      width: 250px;
      cursor: pointer;
      height: 220px;
      background: $about-service-bg;
      margin: 0 auto;
      padding: 35px;
      @include border-radius(22px);

      &.active-card {
        @include gradientBg();

        p {
          color: $white;
        }

        h2 {
          color: $white;
          -webkit-text-fill-color: $white;
        }
      }

      p {
        max-width: 180px;
        padding-top: 20px;
        font-size: 18px;
      }
    }
  }
}

.ourTeamImgContainer {
  margin-top: 45px;

  figure {
    margin-bottom: 0;
  }

  .slick-list {
    overflow: visible;
    overflow-x: clip;
  }

  .team-card {
    height: 360px;
    width: 220px;
    background: $about-team-bg;
    border-radius: 20px;

    p:first-of-type {
      font-size: $fs-h3;
      font-weight: $fw-bold;
    }

    .team-card-img {
      position: relative;
      bottom: 25px;
      right: 19px;
    }
  }
}
.our-team-content {
  width: 90%;
  margin: 0 auto;
}
@media only screen and (max-width: 1560px) {
  .ourserviceList .slick-slider .slick-arrow.slick-prev,
  .ourTeam-sec .slick-slider .slick-arrow.slick-prev {
    left: -5%;
  }
  .ourserviceList .slick-slider .slick-arrow.slick-next {
    right: -5%;
  }
}
@media only screen and (max-width: 992px) {
  .ourTeam-sec .slick-slider .slick-arrow.slick-next {
    right: -5%;
  }
  .our-team-content {
    width: 100%;
  }
}
@media only screen and (max-width: 834px) {
  .ourserviceList .slick-slider .slick-arrow.slick-prev,
  .ourTeam-sec .slick-slider .slick-arrow.slick-prev {
    left: -3%;
  }
  .ourserviceList .slick-slider .slick-arrow.slick-next,
  .ourTeam-sec .slick-slider .slick-arrow.slick-next {
    right: -3%;
  }
  .innovative-sec {
    .our-goals {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media only screen and (max-width: 575px) {
  .innovative-sec {
    .our-goals {
      margin: 0;
      padding-left: 0;
      padding-right: 0;
      .separate-goals {
        .our-goalsImg {
          width: 100%;
        }
      }
    }
  }
}

.ourserviceList {
  .slider {
    .slick-dots {
      width: 100%;
    }
  }
}
.ourTeam-sec {
  .slick-dots {
    width: 90%;
  }
}

@media only screen and (max-width: 330px) {
  .ourTeamImgContainer {
    .team-card {
      .team-card-img {
        right: 20px;
      }
    }
  }
}
